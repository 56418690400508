import { render, staticRenderFns } from "./walkme-widget.vue?vue&type=template&id=37a35d42&scoped=true&"
import script from "./walkme-widget.vue?vue&type=script&lang=ts&"
export * from "./walkme-widget.vue?vue&type=script&lang=ts&"
import style0 from "./walkme-widget.vue?vue&type=style&index=0&id=37a35d42&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a35d42",
  null
  
)

export default component.exports