
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import type UserService from '@/modules/user/user.service';

import AlertItem from './alert-item.vue';
import type AlertsService from '../alerts.service';

@Component({
    components: { AlertItem, CustomLoader },
})
export default class AlertsSection extends Vue {
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.AlertsService) private alertsService!: AlertsService;

    expandedGroups = [true, false, false];

    mounted() {
        let groupsToExpand = [false, false, true];

        if (!this.haveAlerts) {
            groupsToExpand = [false, false, false];
        } else if (this.todayAlerts.length) {
            groupsToExpand = [true, false, false];
        } else if (this.yesterdayAlerts.length) {
            groupsToExpand = [false, true, false];
        }

        if (JSON.stringify(this.expandedGroups) !== JSON.stringify(groupsToExpand)) {
            this.expandedGroups = groupsToExpand;
        }
    }

    get alerts() {
        if (!this.$route.name) {
            return [];
        }

        const level = this.$route.name.split('.')[0].split('-')[0];

        if (level !== this.userService.viewAs) {
            return [];
        }

        const { alerts } = this.alertsService;

        return alerts || [];
    }

    get haveAlerts() {
        return this.alerts.length > 0;
    }

    get haveUnreadAlerts() {
        return this.alerts.map(alert => !alert.read).length > 0;
    }

    get todayAlerts() {
        const today = new Date();
        const allTodayAlerts = this.alerts.filter(a => (a.date.getMonth() === today.getMonth() && a.date.getDate() === today.getDate()));

        return allTodayAlerts;
    }

    get yesterdayAlerts() {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const allYesterdayAlerts = this.alerts.filter(a => (
            a.date.getMonth() === yesterday.getMonth() && a.date.getDate() === yesterday.getDate()
        ));

        return allYesterdayAlerts;
    }

    get otherAlerts() {
        const today = new Date();
        const yesterday = new Date();

        yesterday.setDate(yesterday.getDate() - 1);

        const allOtherAlerts = this.alerts.filter(a => (
            a.date.getDate() !== yesterday.getDate()
            && a.date.getDate() !== today.getDate()
        ));

        return allOtherAlerts;
    }

    get isLoading() {
        return this.alertsService.loading.isLoading();
    }

    handleMarkReadClick() {
        this.alertsService.markAllAlertsAsRead();
    }

    handleGroupHeadingClick(groupIndex: number) {
        this.expandedGroups[groupIndex] = !this.expandedGroups[groupIndex];
        this.expandedGroups = [
            ...this.expandedGroups,
        ];
    }
}
