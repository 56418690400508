import { render, staticRenderFns } from "./chat-button.vue?vue&type=template&id=f5b0b64a&scoped=true&"
import script from "./chat-button.vue?vue&type=script&lang=ts&"
export * from "./chat-button.vue?vue&type=script&lang=ts&"
import style0 from "./chat-button.vue?vue&type=style&index=0&id=f5b0b64a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5b0b64a",
  null
  
)

export default component.exports