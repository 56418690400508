
import { Vue, Prop, Component } from 'vue-property-decorator';
import { Badge } from 'element-ui';

@Component({
    components: {
        'el-badge': Badge,
    },
})
export default class AlertReference extends Vue {
    @Prop({ default: 0 })
    private amountAlerts!: number;

    get value() {
        return this.amountAlerts;
    }
}
