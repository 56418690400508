
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type JiraService from '@/modules/jira/jira.service';

@Component
export default class chatButton extends Vue {
    @inject(KEY.JiraService) private jiraService!: JiraService;

    isChatInited = false;

    async mounted() {
        await this.jiraService.initWidget();
        this.isChatInited = true;
    }

    toggleChat() {
        this.jiraService.toggleChat();
    }
}
