
import { Component, Vue } from 'vue-property-decorator';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';

import AlertItem from './alert-item.vue';
import AlertsSection from './alerts-section.vue';
import NotificationsSection from './notifications-section.vue';

@Component({
    components: {
        CustomLoader, AlertItem, AlertsSection, NotificationsSection,
    },
})
export default class AlertPopupContent extends Vue {
    activeTab = 0;
}
