var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",staticClass:"chocolate-menu"},[_c('button',{class:{
            'chocolate-menu__btn': true,
            'chocolate-menu__btn--active': _vm.active,
        },on:{"click":_vm.toggle}},[_c('span',{staticClass:"icon-Apps-icon"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],staticClass:"chocolate-menu__body"},[_c('a',{attrs:{"href":"/"}},[_c('div',{staticClass:"chocolate-menu__item ci"},[(_vm.userService.isCarUser)?_c('img',{staticClass:"logo",attrs:{"src":require("@/modules/common/assets/fornova-cr-logo.svg"),"alt":"Competetive intelligence"}}):_c('img',{staticClass:"logo",attrs:{"src":require("@/modules/common/assets/fornova-ci-text-logo.svg"),"alt":"Competetive intelligence"}})])]),(_vm.diUrl)?_c('a',{attrs:{"href":_vm.diUrl}},[_vm._m(0)]):_vm._e(),(_vm.biUrl)?_c('a',{attrs:{"href":_vm.biUrl}},[_vm._m(1)]):_vm._e(),(_vm.ecUrl)?_c('a',{attrs:{"href":_vm.ecUrl}},[_vm._m(2)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chocolate-menu__item di"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/modules/common/assets/di.svg"),"alt":"Distribution intelligence"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chocolate-menu__item bi"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/modules/common/assets/ri.svg"),"alt":"Revenue intelligence"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chocolate-menu__item ec"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/modules/common/assets/ec.svg"),"alt":"E-Commerce optimizer"}})])
}]

export { render, staticRenderFns }