
import { Component, Vue } from 'vue-property-decorator';
import { KEY } from '@/inversify.keys';
import { inject } from '@/inversify';
import Notification from '@/modules/notifications/components/notification.vue';
import type NotificationService from '@/modules/notifications/notification.service';

@Component({ components: { Notification } })
export default class NotificationsSection extends Vue {
    @inject(KEY.NotificationService) private notificationService!: NotificationService;

    get notifications() {
        return this.notificationService.archivedNotifications;
    }
}
