var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",class:{
        'alert': true,
        'alert--non-read': !_vm.alert.read,
        'alert--pending': _vm.isPending,
        'alert--expanded': _vm.isExpanded,
    },style:({
        '--height': _vm.targetHeight,
    }),on:{"click":_vm.toggleExpand}},[_c('div',{staticClass:"alert__content"},[_c('div',{staticClass:"alert__head"},[_c('h4',{staticClass:"alert__heading",domProps:{"textContent":_vm._s(_vm.$tc(`alerts.${_vm.alert.alertType}.alert_type`))}}),_c('div',{staticClass:"alert__date-label",domProps:{"textContent":_vm._s(_vm.date)}})]),_c('p',{staticClass:"alert__message",attrs:{"title":_vm.titleMessage}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.message)}}),(_vm.isHaveBehavior)?_c('a',{staticClass:"alert__link",attrs:{"href":"javascript:void(0)"},domProps:{"textContent":_vm._s(_vm.linkLabel)},on:{"click":function($event){$event.stopPropagation();return _vm.runAlertScript.apply(null, arguments)}}}):(_vm.link)?_c('a',{staticClass:"alert__link",attrs:{"href":_vm.link},domProps:{"textContent":_vm._s(_vm.linkLabel)},on:{"click":function($event){$event.stopPropagation();return _vm.markAndOpen.apply(null, arguments)}}}):_vm._e()])]),_c('div',{class:{
            'alert__actions-bar': true,
            'alert__actions-bar--confirm': _vm.isDeleteConfirmationEnabled,
        }},[_c('button',{class:{
                'alert__button alert__button--delete': true,
                'alert__button--confirm': _vm.isDeleteConfirmationEnabled,
            },attrs:{"title":_vm.$tc('notifications.delete')},on:{"click":function($event){$event.stopPropagation();return _vm.deleteAlert.apply(null, arguments)}}},[(!_vm.isDeleteConfirmationEnabled)?_c('i',{staticClass:"icon-X-01"}):_vm._e()]),_c('button',{class:{
                'alert__button': true,
                'alert__button--unread': _vm.alert.read,
            },attrs:{"title":!_vm.alert.read ? _vm.$tc('notifications.asRed') : _vm.$tc('notifications.asUnred')},on:{"click":function($event){$event.stopPropagation();return _vm.toggleReadStatus.apply(null, arguments)}}},[_c('i',{staticClass:"icon-market-visibilty"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }