
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type JiraService from '@/modules/jira/jira.service';
import CustomDropdown from './ui-kit/custom-dropdown.vue';

@Component({
    components: {
        CustomDropdown,
    },
})
export default class CiInfo extends Vue {
    @inject(KEY.JiraService) private jiraService!: JiraService;

    get items() {
        return [
            {
                link: 'https://www.fornova.com/resources',
                text: this.$tc('menu.hub'),
            },
            {
                link: this.jiraService.knowledgeBaseLink,
                text: this.$tc('menu.base'),
            },
            {
                link: this.jiraService.contactUsFormLink,
                text: this.$tc('menu.contact'),
            },
        ];
    }
}
