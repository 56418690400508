
import { Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import AlertReference from '@/modules/alerts/components/alert-reference.vue';
import AlertPopup from '@/modules/alerts/components/alert-popup.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import type AlertsService from '@/modules/alerts/alerts.service';

@Component({
    components: {
        AlertReference,
        AlertPopup,
        LoaderWrapper,
    },
})
export default class AlertsPopupTrigger extends Vue {
    @inject(KEY.AlertsService) private alertsService!: AlertsService;
    @inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;

    isShown = false;

    get amountAlerts(): number {
        let { alerts } = this.alertsService;

        const { hotelId } = this.$route.params;

        if (!this.$route.name) {
            return 0;
        }

        const isHotelLevel = this.$route.name.split('.').shift() === 'hotel';

        if (hotelId && isHotelLevel) {
            alerts = alerts.filter(a => a.fornovaId === +hotelId);
        }

        return alerts ? alerts.filter(a => !a.read).length : 0;
    }

    toggleAlerts() {
        this.isShown = !this.isShown;

        if (this.isShown) {
            window.addEventListener('click', this.checkClickOutside, { capture: true });
        } else {
            window.removeEventListener('click', this.checkClickOutside, { capture: true });
        }

        this.otelService.instantSpan({ name: 'alerts-toggle', prefix: LOGTYPE.CLICK }, { sendLogs: true });
    }

    private checkClickOutside(event: MouseEvent) {
        const { container } = this.$refs as { container: HTMLDivElement };
        const path = event.composedPath();

        if (path.includes(container)) {
            return;
        }

        this.toggleAlerts();
    }
}
