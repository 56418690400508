
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type ConfigService from '@/modules/config/config.service';
import type UserService from '@/modules/user/user.service';

@Component
export default class Chocolate extends Vue {
    @inject(KEY.ConfigService) private configService!: ConfigService;
    @inject(KEY.UserService) public userService!: UserService;

    active: boolean = false;

    toggle() {
        this.active = !this.active;
    }

    closeMenu(e: MouseEvent) {
        const { container } = this.$refs as { container: HTMLDivElement };
        const path = e.composedPath();

        if (!path.includes(container)) {
            this.active = false;
        }
    }

    mounted() {
        window.addEventListener('click', this.closeMenu);
    }

    beforeDestroy() {
        window.removeEventListener('click', this.closeMenu);
    }

    get activeStatus() {
        return this.active;
    }

    get diUrl(): string | null {
        return this.configService.diUrl;
    }

    get biUrl(): string | null {
        return this.configService.biUrl;
    }

    get ecUrl(): string | null {
        return this.configService.ecUrl;
    }
}
